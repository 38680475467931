import { useEffect } from "react";
import ai from "./assets/AI.png";
import logo from "./assets/logo.png";
import "./index.css";
import AnswerAnalytics from "../../components/AnswerAnalytics";
import Chiron from "../../components/Chiron";
import { useCognitoWebhook } from "../../hooks/useCognitoWebhook";

type ResponseFromUser = {
  id: string;
  email: string;
  alias?: string;
  responseToQuestion: string;
  howManyPeopleGuess: number;
};
type BucketedAnswer = {
  originalResponse: ResponseFromUser;
  buckets: {
    name: string;
    technologies: string[];
  }[];
};

const DEFAULT_CHIRON_TEXT =
  "V2 Digital is driven by outcomes, unleashed by technology and powered by humans.";

function App() {
  const { lastJsonMessage } = useCognitoWebhook(
    "wss://6ok62fyhse.execute-api.ap-southeast-2.amazonaws.com/live/",
  );
  let addChironQueue: (message: string) => void = () => {};
  useEffect(() => {
    if (lastJsonMessage) {
      const websocketMessage = lastJsonMessage as BucketedAnswer;
      console.log(
        `adding chiron message: ${websocketMessage.originalResponse.alias}`,
      );
      addChironQueue(
        `New guess from ${websocketMessage.originalResponse.alias || websocketMessage.originalResponse.email}`,
      );
    }
  }, [lastJsonMessage, addChironQueue]);

  return (
    <div className="w-dvw h-dvh bg-gold">
      <div className="flex flex-col h-full">
        <div className="w-full basis-5/6 top-splatter flex pt-8 pl-32 pr-16 gap-8">
          <div className="basis-1/2 h-full flex flex-col align-middle pr-8">
            <div className="logo max-w-72 -ml-4">
              <img src={logo} alt="logo" />
            </div>
            <div className="mt-12">
              <div className="text-4xl mb-2 mt-4">Guessing Comp</div>
              <div className="text-5xl mb-2 mt-4">
                How many people will walk this way tonight?
              </div>
              <div className="min-h-96 mt-16">
                <img src="http://localhost:5001/video_feed" />
              </div>
            </div>
          </div>
          <div className="basis-1/2 h-full flex flex-col justify-center align-middle">
            <AnswerAnalytics />
          </div>
        </div>
        <div className="basis-1/6 flex flex-col">
          <div className="basis-1/4 bottom-splatter"></div>
          <div className="bg-black basis-3/4 flex flex-row px-8">
            <div className="relative w-1/3 h-full flex overflow-visible min-w-0">
              <img className="absolute bottom-0" src={ai} alt="AI" />
            </div>
            <div className="w-2/3 h-full text-center text-white text-6xl font-extralight px-12 py-2">
              <Chiron
                addChironToQueue={(fn) => {
                  addChironQueue = fn;
                }}
                defaultText={DEFAULT_CHIRON_TEXT}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
