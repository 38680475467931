import { QUESTION } from "@v2-aws-summit/common";
import { usePutUserResponse } from "aws-summit-api-typescript-react-query-hooks";
import React, { useState } from "react";
import LinkedInIcon from "./linked-in-black.png";

interface FormData {
  email: string;
  alias?: string;
  answer: string;
  howManyPeople: number;
}

const Mandatory = () => <span className="text-red-700">*</span>;

const SubmissionComplete = ({ user }: { user: string }) => {
  return (
    <div className="max-w-lg mx-auto my-10">
      <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <div className="mb-4">
          <h2 className="text-2xl text-center">
            Thank you for submitting, {user}!
          </h2>
          <a href="https://www.linkedin.com/company/81854932/">
            <img className="max-h-24 m-auto my-8" src={LinkedInIcon} />
          </a>
          <p className="text-xl text-center mt-8">
            Follow V2 on LinkedIn for latest news, insights & job opportunities.
          </p>
        </div>
      </div>
    </div>
  );
};

const Form: React.FC = () => {
  const putUserResponse = usePutUserResponse();
  const [savedSubmissions, setSavedSubmission] = useState<{
    submission: FormData;
    hasSubmitted: boolean;
  }>(() => {
    const localStorageSubmission = localStorage.getItem("submission");
    return localStorageSubmission
      ? {
          submission: JSON.parse(localStorageSubmission),
          hasSubmitted: true,
        }
      : {
          submission: {
            email: "",
            alias: "",
            answer: "",
            howManyPeople: 0,
          },
          hasSubmitted: false,
        };
  });

  const { submission, hasSubmitted } = savedSubmissions;
  const [formData, setFormData] = useState<FormData>(submission);

  const handleChange =
    (parse: (s: string) => unknown = (s) => s) =>
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: parse(value) });
    };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    putUserResponse.mutate(
      {
        putUserResponseBody: {
          email: formData.email,
          alias: formData.alias === "" ? undefined : formData.alias,
          howManyPeopleGuess: formData.howManyPeople,
          responseToQuestion: formData.answer,
        },
      },
      {
        onError: (error) => {
          console.error(error);
        },
        onSuccess: () => {
          console.log("Success");
          localStorage.setItem("submission", JSON.stringify(formData));
          setSavedSubmission({ submission: formData, hasSubmitted: true });
        },
      },
    );
  };

  return (
    <div className="max-w-lg mx-auto my-10">
      {hasSubmitted ? (
        <SubmissionComplete user={formData.alias || formData.email} />
      ) : (
        <form
          onSubmit={handleSubmit}
          className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
        >
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="email"
            >
              Email
              <Mandatory /> (So we can contact you if you win!)
            </label>
            <input
              type="email"
              name="email"
              id="email"
              required
              value={formData.email}
              onChange={handleChange()}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="alias"
            >
              Alias (If you don't want your email shown on the big screen -
              Optional)
            </label>
            <input
              type="text"
              name="alias"
              id="alias"
              value={formData.alias}
              onChange={handleChange()}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="answer"
            >
              {QUESTION}
              <Mandatory />
            </label>
            <textarea
              name="answer"
              id="answer"
              required
              minLength={2}
              value={formData.answer}
              onChange={handleChange()}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="guess"
            >
              And... How many people do you think will pass our booth tonight?
              <Mandatory />
            </label>
            <input
              type="number"
              name="howManyPeople"
              id="howManyPeople"
              required
              value={formData.howManyPeople}
              onChange={handleChange(parseInt)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="flex items-center justify-end mt-8">
            <button
              type="submit"
              className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-4 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Submit
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default Form;
